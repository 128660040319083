function isDomain(str) {
    //eslint-disable-next-line
    const regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;

    if (regexp.test(str)) {
        return true;
    }
    else {
        return false;
    }
}

function buildTable(str, status, htmlFlag) {
    function isNotEmpty(row) {
        return row !== '';
    }

    var allRowsInitial = str.split(/\r?\n|\r/).filter(isNotEmpty);

    const allRows = allRowsInitial.map(function (element, index) {
        if (index === 0) {
            return "Α/Α," + element
        } else {
            return index + "," + element
        }
    });
    //var table = '<table id="csvTable' + status + '" class="sortable">';
    let table
    if (htmlFlag) {
        table = '<table id="csvTable' + status + htmlFlag + '" class="sortable">';
    } else {
        table = '<table id="csvTable' + status + '" class="sortable">';
    }

    for (var singleRow = 0; singleRow < allRows.length; singleRow++) {
        if (singleRow === 0) {
            table += '<thead>';
            table += '<tr>';
        } else {
            table += '<tr>';
        }
        var rowCells = allRows[singleRow].split(',');
        for (var rowCell = 0; rowCell < rowCells.length; rowCell++) {
            if (singleRow === 0) {
                table += '<th aria-sort="ascending">';
                table +=
                    ' <button class="button-22" role="button" title="Κάνετε κλικ για Ταξινόμηση">' + rowCells[rowCell] +
                    '<span aria-hidden="true"></span></button>'
                //rowCells[rowCell];
                table += '</th>';
            } else {
                table += '<td>';
                // File Upload to Formes Minedu case
                if (rowCells[rowCell].startsWith("https://formes.minedu")
                    //to work on DEV, for testing
                    || rowCells[rowCell].startsWith("https://dev-thess.pde.sch")) {
                    //table += `<a href=${rowCells[rowCell]}>` + rowCells[rowCell] + `</a>`
                    if (htmlFlag) { //HTML case for uploaded files
                        table += `<a href=${rowCells[rowCell]}> Μεταφόρτωση αρχείου </a>`
                    } else { //XLSX case for uploaded files
                        table += `<a href=${rowCells[rowCell]}>` + rowCells[rowCell] + `</a>`
                    }
                    // URL field case
                } else if (rowCells[rowCell].startsWith("https://")) {
                    table += `<a href=${rowCells[rowCell]}>` + rowCells[rowCell] + `</a>`
                    // URL field case without 'https' entered by the user
                } else if (isDomain(rowCells[rowCell])) {
                    let tempUrl = 'https://' + rowCells[rowCell]
                    table += `<a href=${tempUrl}>` + rowCells[rowCell] + `</a>`
                }
                else {
                    table += rowCells[rowCell];
                }
                table += '</td>';
            }
        }
        if (singleRow === 0) {
            table += '</tr>';
            table += '</thead>';
            table += '<tbody>';
        } else {
            table += '</tr>';
        }
    }
    table += '</tbody>';
    table += '</table>';

    return table;
}

export default buildTable
