import React from 'react';
import { connect } from 'react-redux';
import Confirm from '../../../../../containers/Confirm';
import { deleteSubmission, resetSubmissions, selectError, Errors, selectRoot } from 'react-formio';
import { push, goBack } from 'connected-react-router';
import { Redirect } from 'react-router-dom';

const Delete = props => {
	const { auth } = props;

	if (auth === undefined || auth.authenticated === false) {
		return <Redirect to="/" />;
	}
	return (
		<div>
			<Errors errors={props.errors} />
			<Confirm {...props} />
		</div>
	);
};

const mapStateToProps = state => {
	const auth = selectRoot('auth', state);
	if (auth === undefined || auth.authenticated === false) return { auth };

	return {
		//message: `Are you sure you wish to delete the submission "${state.submission.submission._id}"?`,
		auth: auth,
		message: `Είστε βέβαιοι ότι θέλετε να διαγράψετε την υποβολή "${state.submission.submission._id}"?`,
		errors: [selectError('submission', state), selectError('form', state)],
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onYes: () => {
			dispatch(
				deleteSubmission('submission', ownProps.match.params.submissionId, ownProps.match.params.formId, err => {
					if (!err) {
						dispatch(resetSubmissions('submissions'));
						dispatch(push(`/form/${ownProps.match.params.formId}/submission`));
					}
				}),
			);
		},
		onNo: () => {
			dispatch(goBack());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
